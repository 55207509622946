<template>
  <div class="bc-cart">
    <div class="component component__padding page">
      <div class="component__title">
        {{ $t('CART.TITLE') }}
      </div>
      <div class="component__subtitle" v-if="!cartItems.length">
        {{ $t('CART.EMPTY_ORDER') }}
        <div class="component__subtitle__go-shop-btn">
          <bc-router-link :to="'/shop'">
            <bc-button>{{ $t('CART.GO_SHOP') }}</bc-button>
          </bc-router-link>
        </div>
      </div>
      <div class="bc-cart__content" v-else>
        <div class="bc-cart__content__items">
          <bc-cart-item
              class="bc-cart__content__items__cart-item"
              v-for="product in cartItems" :key="product.id"
              :product="product">
          </bc-cart-item>
        </div>
        <div class="bc-cart__content__summary">

          <div class="bc-cart__content__summary__title">{{ $t('CART.TOTAL_INFO_1') }}</div>
          <div class="bc-cart__content__summary__title">{{ $t('CART.TOTAL_INFO_2') }}</div>

          <div class="bc-cart__content__summary__total">
            <div class="bc-cart__content__summary__total__title">{{ $t('CART.TOTAL_PRICE') }}</div>
            <div class="bc-cart__content__summary__total__value">{{ cartTotal }} {{ $t('INTERFACE.CURRENCY') }}</div>
          </div>

          <div class="bc-cart__content__summary__ordering">
            {{ $t('CART.ORDERING') }}
          </div>
          <form class="bc-cart__content__summary__form" ref="form">

            <div>
              <input
                  class="sm"
                  :class="{ error: v$.form.name.$errors.length }"
                  type="text"
                  :placeholder="$t('ORDER_FORM.NAME')"
                  v-model.lazy="v$.form.name.$model">
              <Transition name="slide-fadeUp">
                <div
                    class="input-error"
                    v-if="v$.form.name.$errors.length">
                  {{ v$.form.name.$errors[0].$message }}
                </div>
              </Transition>
            </div>

            <div>
              <input
                  class="sm"
                  :class="{ error: v$.form.email.$errors.length }"
                  type="text"
                  :placeholder="$t('ORDER_FORM.EMAIL')"
                  v-model.lazy="v$.form.email.$model">
              <Transition name="slide-fadeUp">
                <div
                    class="input-error"
                    v-if="v$.form.email.$errors.length">
                  {{ v$.form.email.$errors[0].$message }}
                </div>
              </Transition>
            </div>

            <div>
              <input
                  class="sm"
                  :class="{ error: v$.form.phone.$errors.length }"
                  type="text"
                  :placeholder="$t('ORDER_FORM.PHONE')"
                  v-model.lazy="v$.form.phone.$model">
              <Transition name="slide-fadeUp">
                <div
                    class="input-error"
                    v-if="v$.form.phone.$errors.length">
                  {{ v$.form.phone.$errors[0].$message }}
                </div>
              </Transition>
            </div>

            <div>
            <textarea
                class="sm"
                :placeholder="$t('ORDER_FORM.COMMENT')"
                rows="3"
                v-model.lazy="form.message">
            </textarea>
            </div>

            <div class="bc-cart__content__summary__form__actions">
              <bc-progress-circular v-if="isSentForm"/>
              <bc-button
                  :color-type="'white'"
                  :is-big="true"
                  @click="sendForm">
                <span>{{ $t('INTERFACE.ORDER') }}</span>
              </bc-button>
              <bc-thanks-for-order :isActive="isSentForm"></bc-thanks-for-order>
            </div>


          </form>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import bcCartItem from "@/components/UI/shop/bc-cart-item";
import {numberWithSpaces,} from "@/utils";
import useVuelidate from "@vuelidate/core";
import {emailValidation, phoneValidation, requiredValidation} from "@/validators/validators";
import {computed} from "vue";
import {store} from "@/store";
import {useHead} from "@vueuse/head";

export default {
  name: "bc-сart",
  components: {
    bcCartItem
  },
  setup() {
    return {v$: useVuelidate()}
  },
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('CART')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('CART')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('CART')?.title),
    //     computed(() => store.getters.getSeoDataForPage('CART')?.description))
  },
  computed: {
    cartItems() {
      return this.$store.getters.cartItems
    },
    cartTotal() {
      return numberWithSpaces(this.$store.getters.cartTotal)
    }
  },
  methods: {
    cartItemsToSend() {
      let arrayToSend = []
      this.cartItems.forEach((item) => {
        arrayToSend.push({
          product_id: item.id,
          product_option_id: item.preferred_option?.id,
          quantity: item.quantity,
          current_price: item.price
        })
      })
          // .reduce((array, product) => {array.push(product.id)}, [])
      return arrayToSend
    },

    async sendForm() {

      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      const dataToSend = {
        ...this.form,
        products_ordered: this.cartItemsToSend(),
        total_price: this.cartTotal
      }
      this.$http.post('/api/order/shop/', dataToSend).then(() => {
        // this.instaList = resp.data
        this.isSentForm = true

        this.v$.$reset()
        this.form.name = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.message = ''


        setTimeout(() => {
          this.$store.commit('clearCart')
          this.isSentForm = false

        }, 3000)

      }).catch(err => {
        // this.error = true
        console.error(err)
      })
      console.log(this.form)
      this.isSentForm = false
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      isSentForm: false
    }
  },
  validations() {
    return {
      form: {
        name: {
          required_validation: {
            $validator: requiredValidation,
            $message: this.$i18n.t('ORDER_FORM.REQUIRED_ERROR')
          }
        },
        email: {
          required_validation: {
            $validator: requiredValidation,
            $message: this.$i18n.t('ORDER_FORM.REQUIRED_ERROR')
          },
          email_validation: {
            $validator: emailValidation,
            $message: this.$i18n.t('ORDER_FORM.NOT_VALID_EMAIL_ERROR')
          }
        },
        phone: {
          required_validation: {
            $validator: requiredValidation,
            $message: this.$i18n.t('ORDER_FORM.REQUIRED_ERROR')
          },
          phone_validation: {
            $validator: phoneValidation,
            $message: this.$i18n.t('ORDER_FORM.NOT_VALID_PHONE_ERROR')
          }
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hide-glitch {
  min-height: 40vh;
}
.component__subtitle {
  text-align: center;
  height: 100%;
  &__go-shop-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.bc-cart {

  &__content {
    display: flex;
    align-items: flex-start;

    &__items {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-right: 40px;


      &__cart-item {

        &:not(:first-child) {
          margin-top: 20px;
        }
        &:not(:last-child) {
          padding-bottom: 20px;
          border-bottom: 1px #E4E4E4 solid;
        }
      }
    }

    &__summary {
      background-color: $black;
      color: $white;
      padding: 50px;
      min-width: 500px;

      &__title {
        font-size: 1.4em;
      }

      &__total {
        &__title {
          color: $light-gray;
        }
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #555555;
      }

      &__ordering {
        margin-top: 20px;
        font-size: 1.4em;
      }

      &__form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 650px;

        > div {
          position: relative;
          margin-bottom: 1em;
          width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
        }

        &__actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          > button {
            width: 100%;
            margin-top: 20px;
          }
        }
      }

    }

  }
}

@media screen and (max-width: 1500px) {
  .bc-cart {
    &__content {
      &__summary {
        padding: 30px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .bc-cart {
    &__content__summary {
      min-width: 35%;
    }
  }
}
@media screen and (max-width: 960px) {
  .bc-cart {
    &__content {
      flex-direction: column;
    }
    &__content__summary {
      padding-top: 40px;
      margin-top: 40px;
      width: 100%;
      &__title {
        text-align: center;
      }
      &__ordering {
        text-align: center;
      }
      &__form {
        max-width: unset;
      }
    }
  }
}
</style>