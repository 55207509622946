<template>
  <div class="bc-cart-item">
    <bc-router-link :to="`/shop/${product.slug}`">
      <img
          class="bc-cart-item__img"
          :src="product.picture_small ? product.picture_small : require('@/assets/img/shop/default-cart-item.png')"
          alt="">
    </bc-router-link>
    <div class="bc-cart-item__details">
      <div class="bc-cart-item__details__top">
        <div class="bc-cart-item__details__top__title">
          <bc-router-link :to="`/shop/${product.slug}`">
            {{ product.title }}
          </bc-router-link>
          <div class="bc-cart-item__details__top__title__remove"
               @click="removeFromCart">
            <img src="@/assets/img/icons/more.svg" alt="remove-icon">
          </div>
        </div>
        <div class="bc-cart-item__details__top__option" v-if="product.preferred_option">
          {{ product.preferred_option.title }} {{ product.measure }}
        </div>
      </div>

      <div class="bc-cart-item__details__price">{{ product.price }}&nbsp;{{ $t('INTERFACE.CURRENCY') }}</div>
      <div class="bc-cart-item__details__count">
        <bc-count-control :count-model="product.quantity"
                          @increase-count="addToCart"
                          @decrease-count="decrementFromCart">
        </bc-count-control>
        <div class="bc-cart-item__details__count__totalPrice">{{ itemTotalCost }} {{ $t('INTERFACE.CURRENCY') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {numberWithSpaces} from "@/utils";

export default {
  name: "bc-cart-item",
  props: ['product'],
  computed: {
    itemTotalCost() {
      return numberWithSpaces(this.product.price * this.product.quantity)
    }
  },
  methods: {
    removeFromCart() {
      this.$store.commit('removeFromCart', this.product)
    },
    addToCart() {
      this.$store.commit('addToCart', {
        product: this.product, amount: 1
      })
    },
    decrementFromCart() {
      this.$store.commit('decrementFromCart', this.product)
    }
  }
}
</script>

<style scoped lang="scss">
.bc-cart-item {
  position: relative;
  width: 100%;
  display: flex;

  &__img {
    height: 170px;
    width: 150px;
    min-width: 150px;
    border: $light-gray 1px solid;
    margin-right: 40px;
  }

  &__details {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &__top {
      width: 100%;

      &__title {
        font-size: .92em;
        margin-right: 45px;
        //display: flex;
        //justify-content: space-between;


        &__remove {
          position: absolute;
          right: 0;
          top: 0;
          margin-left: 10px;

          img {
            cursor: pointer;
            background-color: $bright-gray;
            border-radius: 50%;
            padding: 6px;
            min-width: 30px;
            max-height: 30px;
            transform: rotate(45deg);
          }
        }
      }

      &__option {
        color: $dark-gray;
        font-size: .75em;
        margin-top: 5px;
      }

    }

    &__price {
      padding: 10px 0;
    }

    &__count {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }
}

@media screen and (max-width: 600px) {
  .bc-cart-item {
    &__img {
      width: 80px;
      height: 80px;
      min-width: unset;
    }

    &__details {
      &__top {
        &__title {
          margin-right: 28px;
          &__remove {
            img {
              padding: 5px;
              min-width: 25px;
              max-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
